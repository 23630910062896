<template>
  <div class="tab">
    <div class="box">
      <div class="item">
        <div class="title">
          <span>吸烟情况</span>
        </div>
        <div class="chart" id="echart"></div>
      </div>
      <div class="item">
        <div class="title">
          <span>饮酒情况</span>
        </div>
        <div class="chart" id="echart1"></div>
      </div>
      <div class="item">
        <div class="title">
          <span>听力情况</span>
        </div>
        <div class="chart" id="echart2"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {},
  data () {
    return {}
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.$nextTick(() => {
      this.canvas()
      this.canvas1()
      this.canvas2()
    })
    window.addEventListener('resize', this.getresize)
  },
  methods: {
    flexWidth (prop, tableData, title, num = 0) {
      if (tableData.length === 0) { // 表格没数据不做处理
        return title.length * 30 + 'px'
      }
      let flexWidth = 0// 初始化表格列宽
      let columnContent = ''// 占位最宽的内容
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      context.font = '14px Microsoft YaHei'
      if ((prop === '') && title) { // 标题长内容少的，取标题的值,
        columnContent = title
      } else { // 获取该列中占位最宽的内容
        let index = 0
        for (let i = 0; i < tableData.length; i++) {
          const nowTemp = tableData[i][prop] + ''
          const maxTemp = tableData[index][prop] + ''
          const nowTempW = context.measureText(nowTemp).width
          const maxTempW = context.measureText(maxTemp).width
          if (nowTempW > maxTempW) {
            index = i
          }
        }
        columnContent = tableData[index][prop]
        // 比较占位最宽的值跟标题、标题为空的留出四个位置
        const columnW = context.measureText(columnContent).width
        const titleW = context.measureText(title).width
        if (columnW < titleW) {
          columnContent = title || '留七个字'
        }
      }
      // 计算最宽内容的列宽
      const width = context.measureText(columnContent)
      flexWidth = width.width + 60 + num
      return flexWidth + 'px'
    },
    getresize () {
      setTimeout(() => {
        this.myChart.resize()
        this.myChart1.resize()
        this.myChart2.resize()
      }, 500)
    },
    canvas () {
      if (!document.getElementById('echart')) return
      var chartDom = document.getElementById('echart')
      this.myChart = this.$echarts.init(chartDom)
      const option = {
        color: ['#45C2E0', '#C1EBDD', '#FFC851', '#5A5476', '#1869A0', '#FF9393'],
        // title: {
        //   text: '合计：6875人',
        //   subtext: '占体检总人数的39.85%',
        //   left: 'center',
        //   top: '45%',
        //   textStyle: {
        //     fontSize: 30,
        //     color: '#454c5c',
        //     align: 'center'
        //   },
        //   subtextStyle: {
        //     fontSize: 16,
        //     color: '#6c7a89'
        //   }
        // },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          textStyle: {
            fontSize: 16
          },
          padding: [20, 20, 20, 20],
          formatter: (name) => {
            var data = option.series[0].data
            var tarValue
            for (var i = 0, l = data.length; i < l; i++) {
              if (data[i].name === name) {
                tarValue = data[i].value
              }
            }
            return `${name} (${tarValue}人)     `
          }
        },
        series: [
          {
            name: '吸烟情况',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            startAngle: 180,
            label: {
              show: true
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              },
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            labelLine: {
              show: true
            },
            data: [
              { value: 1048, name: '从不吸烟', label: { color: '#45C2E0' } },
              { value: 735, name: '吸烟', label: { color: '#C1EBDD' } },
              { value: 580, name: '已戒烟', label: { color: '#FFC851' } }
            ],
            textStyle: {
              color: function (colors) {
                var colorList = ['#45C2E0', '#C1EBDD', '#FFC851', '#5A5476', '#1869A0', '#FF9393']
                return colorList[colors.dataIndex]
              }
            }
          }
        ]
      }
      option && this.myChart.setOption(option)
    },
    canvas1 () {
      if (!document.getElementById('echart1')) return
      var chartDom = document.getElementById('echart1')
      this.myChart1 = this.$echarts.init(chartDom)
      const option = {
        color: ['#45C2E0', '#C1EBDD', '#FFC851', '#5A5476', '#1869A0', '#FF9393'],
        // title: {
        //   text: '合计：6875人',
        //   subtext: '占体检总人数的39.85%',
        //   left: 'center',
        //   top: '45%',
        //   textStyle: {
        //     fontSize: 30,
        //     color: '#454c5c',
        //     align: 'center'
        //   },
        //   subtextStyle: {
        //     fontSize: 16,
        //     color: '#6c7a89'
        //   }
        // },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          textStyle: {
            fontSize: 16
          },
          padding: [20, 20, 20, 20],
          formatter: (name) => {
            var data = option.series[0].data
            var tarValue
            for (var i = 0, l = data.length; i < l; i++) {
              if (data[i].name === name) {
                tarValue = data[i].value
              }
            }
            return `${name} (${tarValue}人)     `
          }
        },
        series: [
          {
            name: '饮酒情况',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            startAngle: 180,
            label: {
              show: true
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              },
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            labelLine: {
              show: true
            },
            data: [
              { value: 1048, name: '不饮酒', label: { color: '#45C2E0' } },
              { value: 735, name: '饮酒', label: { color: '#C1EBDD' } }
            ],
            textStyle: {
              color: function (colors) {
                var colorList = ['#45C2E0', '#C1EBDD', '#FFC851', '#5A5476', '#1869A0', '#FF9393']
                return colorList[colors.dataIndex]
              }
            }
          }
        ]
      }
      option && this.myChart1.setOption(option)
    },
    canvas2 () {
      if (!document.getElementById('echart2')) return
      var chartDom = document.getElementById('echart2')
      this.myChart2 = this.$echarts.init(chartDom)
      const option = {
        color: ['#45C2E0', '#C1EBDD', '#FFC851', '#5A5476', '#1869A0', '#FF9393'],
        // title: {
        //   text: '合计：6875人',
        //   subtext: '占体检总人数的39.85%',
        //   left: 'center',
        //   top: '45%',
        //   textStyle: {
        //     fontSize: 30,
        //     color: '#454c5c',
        //     align: 'center'
        //   },
        //   subtextStyle: {
        //     fontSize: 16,
        //     color: '#6c7a89'
        //   }
        // },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          textStyle: {
            fontSize: 16
          },
          padding: [20, 20, 20, 20],
          formatter: (name) => {
            var data = option.series[0].data
            var tarValue
            for (var i = 0, l = data.length; i < l; i++) {
              if (data[i].name === name) {
                tarValue = data[i].value
              }
            }
            return `${name} (${tarValue}人)     `
          }
        },
        series: [
          {
            name: '听力情况',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            startAngle: 180,
            label: {
              show: true
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              },
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            labelLine: {
              show: true
            },
            data: [
              { value: 1048, name: '听见', label: { color: '#45C2E0' } },
              { value: 735, name: '听不清或无法听见', label: { color: '#C1EBDD' } }
            ],
            textStyle: {
              color: function (colors) {
                var colorList = ['#45C2E0', '#C1EBDD', '#FFC851', '#5A5476', '#1869A0', '#FF9393']
                return colorList[colors.dataIndex]
              }
            }
          }
        ]
      }
      option && this.myChart2.setOption(option)
    },
    handleSelect (key) {
      this.activeIndex = key
    },
    openTest () {
      this.showTest = true
    },
    closeTest () {
      this.showTest = false
    },
    openDetection () {
      this.showDetection = true
    },
    closeDetection () {
      this.showDetection = false
    },
    openTestDetection () {
      this.showTestDetection = true
    },
    closeTestDetection () {
      this.showTestDetection = false
    }
  }
}
</script>

<style lang="less" scoped>
.tab {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .box {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 20px;
    border: 1px solid #EBEEF5;
    box-sizing: border-box;

    .item {
      flex: 1;
      height: 100%;
      border: 1px solid #EBEEF5;
      border-radius: 6px;
      box-sizing: border-box;
    }

    .title {
      width: 100%;
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid #EBEEF5;
      text-align: center;
      box-sizing: border-box;
      font-size: 22px;
    }

    .chart {
      width: 100%;
      height: calc(100% - 50px);
    }
  }
}
</style>
